import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private readonly errorHandleSubject = new Subject<HttpErrorResponse>();

  public getErrorHandler$() {
    return this.errorHandleSubject.asObservable().pipe(shareReplay(1));
  }

  public setErrorHandler = (error: HttpErrorResponse) => {
    this.errorHandleSubject.next(error);
  };

  public hasControlledError(errorHttp: HttpErrorResponse) {
    return (
      errorHttp?.ok === false &&
      errorHttp?.status === 400 &&
      errorHttp?.error?.errors?.length &&
      errorHttp?.error?.errors[0]?.hasOwnProperty('code')
    );
  }

  public getErrorCode(errorHttp: HttpErrorResponse) {
    if (!this.hasControlledError(errorHttp)) {
      return null;
    }

    return errorHttp?.error?.errors[0]?.code;
  }
}
